<template>
    <div class="about-box" id="about-box">
        <title-nav v-if="$store.state.userAgent === 'Mobile'"/>
        <div class="content">
            <div class="title">这个社区有何价值？</div>
            <div class="answer">我们把他定位为文案笔记社区。不论用于短视频(口播、中视频等等)，还是各大图文制作。这里聚集简洁靠谱的文案笔记。</div>
            <div class="title">愿景与目标是什么？</div>
            <div class="answer">愿景：赋能消防资讯领域，赋能氛围灯测评领域，成为有价值社区。目标：全网精准用户流量起爆，赋能小白从零到一做项目。</div>
            <div class="title">社区有怎样的氛围？</div>
            <div class="answer">我们致力于打造高素质、可信赖的社区氛围。欢迎销售工程师、测评领域意见领袖、消防领域大中小V、灯具经销商一同参与社区运营。</div>
            <div class="title">怎样让用户有获得感？</div>
            <div class="answer">在社区内用户可以找到需要的文案笔记；在商城内用户可以下单需要的商品；用户在此过程中得到比较良好的选品体验</div>
        </div>
    </div>
</template>

<script>
import TitleNav from '../../components/nav/TitleNav.vue'
export default {
  name: 'Vision',
  components: {
    TitleNav
  },
  mounted(){
    if(this.$store.state.userAgent !== 'Mobile'){
        document.getElementById("about-box").style.cssText= 'margin-top: 98px;height:100%';
    }
  },
  data(){
      return {
          
      }
  },
  methods:{
    aboutUs(){

    }
  },
  props:{

  }
}
</script>

<style scoped lang="scss">
.about-box {
    background: #fff;
    .content {
        margin: 50px 10px 10px;
        padding-top: 10px;
        div {
            padding:5px 0;
        }
        .title {
            text-align: left;
            font-size: 22px;
        }
        .answer {
            text-align: left;
            color: rgba(0,0,0,0.5)
        }
    }
}
</style>
